.tableContainer {
    height: 100%;
    overflow: scroll;

}

.tableHead>th {
    text-align: center;
}

.tableBody>td {
    text-align: center;
}

.pro-table {
    height: 100%;
}

.tableBodyPro {
    height: 100%;
    overflow: scroll;
}


.pro-sticky-head {
    background-color: lightgrey;
    top: 0;
    position: sticky;
}

.pro-table-coloum-border td {
    border-left: 1px solid #000;
    border-right: 1px solid #000;
}

.pro-table-coloum-border td:first-child {
    border-left: none;
}

.pro-table-coloum-border td:last-child {
    border-right: none;
}

.pro-overFlow-Y-scroll {
    overflow-y: scroll;
}

* {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}