.OPB{
    margin: 0 ;
     padding: 0;
    background:"#eeeeee"
}
.OPB1{
    background-color: "#eeeeee";
}
.OPB2{
    background-color: "#eeeeee";
}