/* @font-face {
  font-family: MAIAN;
  src: url(./MAIAN.TTF);
}

body {
  font-family:MAIAN ;
  margin-top: 20px;
  overflow-y: scroll;
  height: 100vh;
} */

/* Hide scrollbar for Chrome, Safari and Opera */

:root {
  --blue: #55374a;
  --white: #ffffff;
  --prochure: #55374a;
  --display: var(--display);
}

#root {
  height: 100vh;
}

*::-webkit-scrollbar {
  display: none;
}

.cls-8 {
  font-size: 100px;
}

.cls-8,
.cls-9 {
  fill: #ffffff;
  font-family: 'Maiandra GD';
}

@font-face {
  font-family: MAIAN;
  src: url(MAIAN.TTF);
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.valid {
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 99998;
}

.testtools {
  position: absolute;
  z-index: 99998;
  background-color: rgba(0, 0, 0, 0.481);
  height: 100vh;
  width: 400px;
}

#floatbutton {
  position: absolute;
  top: 50%;
  z-index: 99999;
}

#Tommy {
  display: none;
}

@media only screen and (pointer: coarse) and (min-width: 320px) and (max-width: 1002px) and (orientation: landscape) {
  #Tommy {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    color: #55374a;
  }
}

@media screen and (orientation: landscape) {
  .mobileclass {
    display: none;
  }
}

.pro-spaces-button {
  border-color: #55374a;
  background-color: transparent;
  color: #55374a;
  text-transform: uppercase !;
}

.pro-spaces-button:hover {
  background-color: #55374a;
  color: white;
}

.pro-spaces-button2 {
  background-color: #55374a;
  color: white;
  border-radius: 20px;
}

.cls-8 {
  font-size: 100px;
}

.cls-8,
.cls-9 {
  fill: #55374a;
  font-family: 'Maiandra GD';
}

/* css sabari */

.cardBoxii {
  position: relative;
  width: 100%;
  padding: 20px;
  display: grid;
  /* grid-template-columns: repeat(4,1fr); */
  grid-gap: 30px;
  margin-top: 100px;
  display: table;
}

.tefnmbdfmpbompdmb {
  text-decoration: none;
}

.cardBoxi .cardfgt {
  position: relative;
  background: var(--white);
  padding: 30px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.4);
  /* height: 415px; */
  width: 596px;
  min-width: 300px;
  display: table-cell;
  vertical-align: middle;
}

.cardBoxi .cardjob .numbers {
  position: relative;
  font-weight: 500;
  font-size: 2.5em;
  color: #55374a;
}

.cardBoxi .cardjob .cardName {
  color: var(--black2);
  font-size: 1.1em;
  margin-top: 5px;
}

.cardBoxi .cardjob .iconBox {
  font-size: 3.5em;
  color: var(--black2);
}

.cardBoxi .cardjob:hover {
  background: #55374a;
}

.cardBoxi .cardjob:hover .numbers,
.cardBoxi .cardjob:hover .cardName a,
.cardBoxi .cardjob:hover .iconBox {
  color: var(--white);
}

.cardName ul li a {
  text-decoration: none;
  color: #55374a;
}

.carrrrbus {
  margin-top: 50px;
  position: relative;
  background: var(--white);
  padding: 30px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.4);
  height: auto;
  text-decoration: none;
  color: #55374a;
}

.pro-spaces-button3 {
  color: #fff !important;
  background-color: #55374a !important;
}

.wgfiuwrgiu {
  text-decoration: none;
}

.carrrrbus:hover {
  background-color: #55374a;
  color: white;
}

.slhishieo3jfiehf {
  padding-top: 100px;
}

.slhishieo3jfiehfjefbewf {
  padding-top: 80px;
}

/* @media only screen and (max-width: 1558px) {
 
 .carrrrbus {
     height: 300px;
 }
}

@media only screen and (max-width: 1010px) {
 
 .carrrrbus {
     height: 350px;
 }
}

@media only screen and (max-width: 803px) {
 
 .carrrrbus {
     height: auto;
 }
}

@media screen and (max-width: 715px) {
 
 .carrrrbus {
     height: auto;
 }
}

@media screen and (max-width: 700px) {
 
 .carrrrbus {
     height: auto;
 }
} */

@media screen and (max-width: 550px) {
  .cardfgt {
    font-size: 13px;
  }

  .cardBoxi {
    margin-top: 10px;
  }
}

@media screen and (max-width: 1489px) {
  .rgkerpkgbepkgpetkg {
    height: 300px;
  }
}

@media screen and (max-width: 1024px) {
  .rgkerpkgbepkgpetkg {
    height: 359px;
  }
}

@media screen and (max-width: 926px) {
  .rgkerpkgbepkgpetkg {
    height: 418px;
  }
}

@media screen and (max-width: 779px) {
  .rgkerpkgbepkgpetkg {
    height: 418px;
  }
}

@media screen and (max-width: 767px) {
  .rgkerpkgbepkgpetkg {
    height: auto;
  }
}

/* search result.css */

.card-box {
  padding: 20px;

  background-color: #fff;
  border-radius: 15px;

  padding-top: 20px;
  border: 2px solid #55374a;
}

.eofuvhoefhv {
  font-size: 13px;
}

.khksbvfwe {
  margin-top: -30px;
  margin-right: -30px;
}

.rigehioerhog {
  font-weight: 400;
}

.iorughoisrhgiohs {
  margin-top: 17px;
}

.ufghsuhie {
  color: #9e9999;
}

@media screen and (max-width: 845px) {
  .iorughoisrhgiohs {
    margin-top: 16px;
    margin-left: -10px;
  }
}

@media screen and (max-width: 845px) {
  .khksbvfwe {
    margin-top: -45px;
  }
}

@media screen and (max-width: 767px) {
  .khksbvfwe {
    margin-top: -30px;
  }
}

.social-links li a {
  border-radius: 50%;
  color: rgba(121, 121, 121, 0.8);
  display: inline-block;
  height: 30px;
  line-height: 27px;
  border: 2px solid rgba(121, 121, 121, 0.5);
  text-align: center;
  width: 30px;
}

.social-links li a:hover {
  color: #797979;
  border: 2px solid #797979;
}

.thumb-lg {
  height: 88px;
  width: 88px;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.bt2 {
  width: 105px;
}

.btnsabari {
  background-color: #55374a;
  color: white;
}

.btnsabari:hover {
  background-color: #55374a;
  color: white;
}

.fon {
  font-size: 14px;
}

.gtr {
  margin-left: -8px;
}

.btn3 {
  background-color: #55374a;
  color: white;
}

.btn3:hover {
  color: white;
}

.text-pink {
  color: #ff679b !important;
}

.btn-rounded {
  border-radius: 2em;
}

.text-muted {
  color: #98a6ad !important;
}

h4 {
  line-height: 22px;
  font-size: 18px;
}

@media screen and (max-width: 356px) {
  .font {
    font-size: 10px;
  }
}

/* feedback.css */

.card111 {
  width: 700px;
  border: 3px solid #55374a;
  margin-top: 110px;
}

.header {
  color: white;
  background-color: #55374a;
}

.textareafeed {
  border-color: #55374a;
  box-shadow: none !important;
  -webkit-appearance: none;
  outline: 0px !important;
}

.btncolj {
  background-color: #55374a;
  color: white;
}

.btncolj:hover {
  color: #f4f7f6;
}

@media screen and (max-width: 888px) {
  .card111 {
    width: 550px;
  }
}

@media screen and (max-width: 740px) {
  .card111 {
    width: 450px;
  }
}

@media screen and (max-width: 668px) {
  .card111 {
    width: 400px;
  }
}

.carrrrbuss {
  margin-top: 30px;
  position: relative;
  background: var(--white);
  padding: 30px;
  border-radius: 20px !important;
  display: flex;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.4);
}

.wgfiuwrgiuwdjbcb {
  color: var(--blue);
  text-decoration: none;
}

/* activeaccountsettings */

.text-muted {
  color: #aeb0b4 !important;
}

.text-muted {
  font-weight: 300;
}

.pro-spaces-button {
  border-color: #55374a !important;
  background-color: transparent !important;
  color: #55374a !important;
}

.pro-spaces-button:hover {
  background-color: #55374a !important;
  color: white !important;
}

.pro-spaces-button2 {
  background-color: #55374a;
  color: white;
  border-radius: 20px;
}

.vertical {
  font-size: 20px;
}

.headding {
  font-size: 30px;
  color: #55374a;
}

.car {
  margin-top: 10px;
}

h2 {
  font-weight: 900;
}

.bg-pri {
  background-color: #55374a;
  color: white;
}

.btn-war {
  background-color: #55374a;
  color: white;
  border-radius: 30px;
}

.cardotp {
  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  border: 0;
  border-radius: 1rem;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px);
}

.card h5 {
  overflow: hidden;
  height: 56px;
  font-weight: 900;
  font-size: 1rem;
}

.card-img-top {
  width: 100%;
  max-height: 180px;
  object-fit: contain;
  padding: 30px;
}

.card h2 {
  font-size: 1rem;
}

.cardotp:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(199, 30, 30, 0.06);
}

.label-top {
  position: absolute;
  background-color: #8bc34a;
  color: #fff;
  top: 8px;
  right: 8px;
  padding: 5px 10px 5px 10px;
  font-size: 0.7rem;
  font-weight: 600;
  border-radius: 3px;
  text-transform: uppercase;
}

.top-right {
  position: absolute;
  top: 24px;
  left: 24px;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  font-size: 1rem;
  font-weight: 900;
  background: #ff5722;
  line-height: 90px;
  text-align: center;
  color: white;
}

.top-right span {
  display: inline-block;
  vertical-align: middle;
}

.fon {
  font-size: 14px;
}

.gtr {
  margin-left: -8px;
}

.btn-war:hover {
  color: white;
}

.btncheck {
  border-radius: 20px;
  background-color: #55374a;
  color: white;
}

.btncheck:hover {
  color: white;
}

.checkboxpro {
  height: 30px;
  width: 30px;
  border: 2px solid #55374a;
}

.checkboxpro:focus {
  box-shadow: none;
  outline: none;
}

/* myproduct */

.btnview {
  background-color: #55374a;
  color: white;
  font-weight: 900;
}

.btnview:hover {
  border-color: #55374a;
  background-color: white;
  color: #55374a;
  font-weight: 900;
}

@media (max-width: 768px) {
  .card-img-top {
    max-height: 250px;
  }
}

.over-bg {
  background: rgba(53, 53, 53, 0.85);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
  border-radius: 10px;
}

/* .btn {
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  padding: 5px 50px 5px 50px
}

.box .btn {
  font-size: 1.5rem
}

@media (max-width: 1025px) {
  .btn {
      padding: 5px 40px 5px 40px
  }
}

@media (max-width: 250px) {
  .btn {
      padding: 5px 30px 5px 30px
  }
}

.btn-warning {
  background: none #f7810a;
  color: #ffffff;
  fill: #ffffff;
  border: none;
  text-decoration: none;
  outline: 0;
  box-shadow: -1px 6px 19px rgba(247, 129, 10, 0.25);
  border-radius: 100px
}

.btn-warning:hover {
  background: none #ff962b;
  color: #ffffff;
  box-shadow: -1px 6px 13px rgba(255, 150, 43, 0.35)
}

.bg-success {
  font-size: 1rem;
  background-color: #f7810a !important
}

.bg-danger {
  font-size: 1rem
} */

.price-hp {
  font-size: 1rem;
  font-weight: 600;
  color: darkgray;
}

.amz-hp {
  font-size: 0.7rem;
  font-weight: 600;
  color: darkgray;
}

.fa-question-circle:before {
  color: darkgray;
}

.fa-plus:before {
  color: darkgray;
}

.box {
  border-radius: 1rem;
  background: #fff;
  box-shadow: 0 6px 10px rgb(0 0 0 / 8%), 0 0 6px rgb(0 0 0 / 5%);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
}

.box-img {
  max-width: 300px;
}

.thumb-sec {
  max-width: 300px;
}

@media (max-width: 576px) {
  .box-img {
    max-width: 200px;
  }

  .thumb-sec {
    max-width: 200px;
  }
}

.inner-gallery {
  width: 60px;
  height: 60px;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin: 1px;
  display: inline-block;
  overflow: hidden;
  -o-object-fit: cover;
  object-fit: cover;
}

@media (max-width: 370px) {
  .box .btn {
    padding: 5px 40px 5px 40px;
    font-size: 1rem;
  }
}

.disclaimer {
  font-size: 0.9rem;
  color: darkgray;
}

.related h3 {
  font-weight: 900;
}

footer {
  background: #212529;
  height: 80px;
  color: #fff;
}

/*  */
.btn-dosabutton {
  color: white !important;
  background-color: #55374a !important;
}

.btn-dosabutton:hover {
  color: white;
  background-color: #55374a;
}

.btn-dosabutton2 {
  background-color: white;
  color: var(--blue);
  border: 1px solid var(--blue);
}

.btn-dosabutton2:hover {
  background-color: white;
  color: var(--blue);
  border: 1px solid var(--blue);
}

.btnjui {
  background-color: #55374a !important;
  color: white !important;
  border-radius: 30px !important;
}

.btnjui:hover {
  color: white !important;
}

.btnjui:focus {
  box-shadow: none;
  outline: none;
}

.bracketBrake {
  white-space: nowrap;
}

.fontSizerforlabel {
  font-size: 15px !important;
}

.MuiTablePagination-root p {
  margin-bottom: 0px !important;
}

/* portfolio css */

.postInput {
  width: 100%;
  border: none;
  min-height: 300px;
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
}

.LinkPejovjqepvj {
  text-decoration: none;
  color: var(--blue);
}

.postInput:active {
  outline: none;
  width: 100%;
  border: none;
  min-height: 300px;
  border: none;
  box-shadow: none;
}

.postIn {
  /* min-height: 50px; */
  margin-top: 0px;
  margin-bottom: 0px;
  height: 50px;
  border-radius: 30px;
  /* padding-top: 5px; */
  padding-left: 15px;
  line-height: 16px;
  font-size: 14px;
  width: 100%;
}

.posttoolbarcontainer {
  width: 64px;
}

.eihfioehfoieh {
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  outline-color: transparent;
}

.eihfioehfoieh:active {
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  outline-color: transparent;
  outline: none;
  box-shadow: none;
  border: 0px transparent;
}

@media only screen and (max-width: 576px) {
  .posttoolbarcontainer {
    width: 54px;
  }
}

/* .modaltoolbar{
  height: 100px;
} */

.postMediacontainer {
  background-color: #2f2d30;
  width: 100%;
  /* height: 420px; */
  padding: auto;
}

.luide {
  position: relative;
  top: 50%;
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  max-width: 100%;
  max-height: 100%;
}

.toolbarbtn {
  border-color: var(--blue);
  color: var(--blue);
}

.redbtn {
  background-color: red;
  border: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.yellowbtn {
  background-color: yellow;
  border: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.greenbtn {
  background-color: green;
  border: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.btntext {
  display: none;
  position: fixed;
  margin-top: -21px;
  text-align: center;
}

.ride:hover .btntext {
  margin-left: -8px;
  display: block;
}

.ride2:hover .btntext {
  display: block;
}

.ride3:hover .btntext {
  display: block;
}

#DraftorPost {
  z-index: 2000;
}

/* The Modal (background) */
.modalt {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-contentt {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  padding-top: 0px;
  border: 1px solid #888;
  width: 80%;
}

.closet {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  width: fit-content;
  padding-left: 0px;
}

.backset {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  width: fit-content;
  padding-left: 0px;
}

.closet:hover,
.closet:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.aaa {
  background-color: #fff;
  text-align: center;
  height: 350px;
  padding: 10px;
}

.carItem {
  width: 100%;
  max-height: 340px;
  height: fit-content;
  object-fit: contain;
}

.pro-spaces-button-semi {
  border-color: var(--blue) !important;
  background-color: transparent !important;
  color: var(--blue) !important;
}

.pro-spaces-button-semi:hover {
  border-color: var(--blue) !important;
  background-color: transparent !important;
  color: var(--blue) !important;
}

.advertisementimgbtn {
  background-color: white;
  color: var(--blue);
  border: 1px solid var(--blue);
  margin: 3px;
  border-radius: 5px;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border-color: #ced4da !important;
  border: 1px solid #ced4da !important;
  background-image: none !important;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size='1'],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size='1'] {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border-color: #ced4da !important;
  border: 1px solid #ced4da !important;
  background-image: none !important;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: #55374a !important;
}

.form-check-input.is-valid,
.was-validated .form-check-input:valid {
  border-color: #55374a !important;
}

.Mui-error {
  border-color: #dc3545 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
}

/* .form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e), url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e);
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);


} */

.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size='1'],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size='1'] {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="%23343a40" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2 5l6 6 6-6"/></svg>') !important;
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
  color: #212529 !important;
}

.modal-custom {
  max-width: 90% !important;
  width: 90% !important;
  max-height: 90% !important;
  height: 90% !important;
  z-index: 70000000000 !important;
}

.vipar {
  font-family: 'monotype Corsiva';
  color: rgb(153, 208, 80) !important;
}

@font-face {
  font-family: 'Monotype Corsiva';
  src: url('../src/font/Monotype_Corsiva.ttf') format('truetype');
}

.password-input1122 {
  position: relative;
}

.password-toggle1122 {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}

.showbutton123321 {
  margin-left: -20px !important;
}

.passInout12321 {
  display: inline !important;
  width: calc(100% - 5px) !important;
  margin-right: -5px !important;
}

.hpop {
  list-style-type: none;
  padding: 0px;
}

.hpop li {
  height: 50px !important;
  /* background-color:red; */
  margin-top: 2px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  border-bottom: 1px solid rgba(176, 173, 173, 0.47);
}

.linkedin {
  display: none;
}

.messenger {
  display: none;
}

.newprochureoutlinrbutton {
  color: white !important;
  /* border-radius: px !important; */
  border-color: white !important;
}

.affcheckbox {
  border: 1px solid black !important;
}

.navezindex {
  z-index: 9999 !important;
}

.prochure1 {
  font-family: 'maindra' !important;
  color: white !important;
}

@media only screen and (max-width: 425px) {
  .centerProMax2-1 {
    transform: translateY(-55%) !important;
  }

  .centerProMax2-2 {
    transform: translateY(-160%) !important;
  }
}

.caro-pro-249882 .carousel-control-prev {
  background-color: black !important;
  height: 40px !important;
  width: 40px !important;
  top: calc(50% - 30px) !important;
}

.caro-pro-249882 .carousel-control-next {
  background-color: black !important;
  height: 40px !important;
  width: 40px !important;
  top: calc(50% - 30px) !important;
}

.caro-toolbarv2 {
  height: 300px;
}

.postView {
  max-width: 700px;
}

/* .caro-pro-249882 .carousel-control-prev {
  position: absolute !important;
  top: 130px !important;
  display: flex !important;
}

.caro-pro-249882 .carousel-control-next{
  position: absolute !important;
  top: 130px !important;
  display: inline !important;
} */

.postView .postData {
  max-height: 400px !important;
}

.dropV2 {
  background-color: #55374a00 !important;
  padding: 0px !important;
  margin: 0px !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.dropv2Head .dropdown-toggle {
  /* display: none; */
}

.drop-downlink-pro-forum {
  text-decoration: none;
  color: inherit;
}

.drop-downlink-pro-forum:hover {
  color: inherit;

}

.card_information {
  width: 300px;
  /* Adjust the width as needed */
  height: 200px;
  /* Adjust the height as needed */
  background-color: white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  /* Shadow properties */
  display: flex;
  align-items: center;
  justify-content: center;
}

.card_information-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  background-color: white;
  transition: background-color 0.3s;
}

.card_information-link:hover {
  background-color: var(--blue);
  color: #fff;
}

.card_information-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card_information-text {
  color: var(--blue);
}

.custom-card {
  height: 200px;
  /* Set your desired fixed height */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  /* Add the box shadow */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-container {
  width: 80px;
  /* Set the width of the image container */
  height: 80px;
  /* Set the height of the image container */
  border-radius: 50%;
  /* Make the container round-shaped */
  overflow: hidden;
  margin-bottom: 10px;
  /* Add some spacing between the image and the title */
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Additional styling for the title and text, if needed */

.btnsabari1 {
  background-color: var(--blue) !important;
  color: white !important;
  border-radius: 25%
}

.btnsabari1:hover {
  background-color: var(--blue) !important;
  color: white !important;
}

.react-bootstrap-dropdown-item:hover {
  /* background-color: transparent; Optional: If you want to remove the background color on hover */
  color: white !important;
  /* Set the text color to white on hover */
}

.btnjui2 {
  color: var(--blue) !important;
  background-color: white !important;
  border-color: var(--blue) !important;
  border-radius: 30px !important;
}

.btnjui2:hover {
  color: var(--blue) !important;
  border-color: var(--blue) !important;
  background-color: white !important;
  border-radius: 30px !important;
}

.btnjui2:focus {
  color: var(--blue) !important;
  border-color: var(--blue) !important;
  background-color: white !important;
  border-radius: 30px !important;
}

.lable-left label {
  width: 100%;
  padding: 5px;
  text-align: left;
}