
.emp-profile{
    padding-left: 3%;
    padding-right: 3%;
    padding-bottom: 2.5%;
    margin-top: 1.5%;
    margin-bottom: 1%;
    border-radius: 0.5rem;
    background: white;
    padding-top: 60px; 
}
.profilee-img{
    text-align: center;
}
.profilee-img img{
    width: 40%;
    border-radius: 15%;
    margin-bottom: 20px;
    border: 3px solid var(--blue);
    
}
.profile-head h5{
    color: #333;
}
.profile-head h6{
    color: var(--blue);
}
.profile-edit-btndghshgsih{
    
    border-radius: 1.5rem;
    /* width: 70%; */
    padding: 2%;
    font-weight: 600;
    color: var(--blue);
    border-color: var(--blue);
    background-color: transparent;
    cursor: pointer;
    touch-action: auto;
}

.seiufgigfiuwegiuowebh{
    z-index: 10000000;
}

    


.whgiowhroighsoihlznvlsnd{
    padding-top: 100px;
}
.proile-rating{
    font-size: 15px;
    color: #495057;
    
    margin-top: 5%;
    font-weight: 600;
}
.proile-rating span{
    color: #818182;
    font-size: 15px;
    
}
.profile-head .nav-tabs{
    margin-bottom:5%;
}
.profile-head .nav-tabs .nav-link{
    font-weight:600;
    border: none;
}
.profile-head .nav-tabs .nav-link.active{
    border: none;
    border-bottom:2px solid var(--blue);
}
.profile-work{
    padding: 14%;
    margin-top: -15%;
    margin-bottom: 65px;
}
.profile-work p{
    font-size: 12px;
    color: #818182;
    font-weight: 600;
    margin-top: 10%;
}
.profile-work a{
    text-decoration: none;
    color: #495057;
    font-weight: 600;
    font-size: 14px;
}
.profile-work ul{
    list-style: none;
}
.profile-tab label{
    font-weight: 600;
}
.listcolor {
    background-color: whitesmoke;
    border-top: none;
    border-left: none;
    border-right: none;
}
.nav-item {
    z-index: 100;
}
.address {
    word-break: keep-all;
}
.scroll {
    overflow-y: scroll;
    height: 400px;
}
.nav-link {
    color: var(--blue);
}
.nav-link:hover {
    color: var(--blue);
}
/* Hide scrollbar for Chrome, Safari and Opera */
.scroll::-webkit-scrollbar {
    display: none;
} 
  /* Hide scrollbar for IE, Edge and Firefox */
.scroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.body {
    height: 700px;
}
.modall {
    padding-left: 320px;
}
.LinkP{
    padding: 5px;
    text-decoration: none;
}
.linkPcontainer{
width: 100%;
}
.portfolioLink{
    text-decoration: none;
}
.pTitle{
    width: 100%;
    outline: none;
    border: none;
}
.postInput {

    width: 100%;
    border: none;
    min-height: 300px;
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
}
.postInput:active {
    outline: none;
    width: 100%;
    border: none;
    min-height: 300px;
    border: none;
    box-shadow: none;
}
.postIn {
    /* min-height: 50px; */
    margin-top: 0px;
    margin-bottom: 0px;
    height: 50px;
    border-radius: 30px;
    /* padding-top: 5px; */
    padding-left: 15px;
    line-height: 16px;
    font-size: 14px;
    width: 100%;
}
.posttoolbarcontainer {
    width: 64px;
}
@media screen and (max-width:1300px){
    .modall {
        padding-left: 190px;
    }
}
@media only screen and (max-width:576px) {

    .posttoolbarcontainer {
        width: 54px;
    }
    .modall {
        padding-left: 0;
    }

}

/* .modaltoolbar{
    height: 100px;
} */

.postMediacontainer {
    background-color: #2f2d30;
    width: 100%;
    /* height: 420px; */
    padding: auto;

}


.luide {
    position: relative;
    top: 50%;
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;

}

.toolbarbtn{
    border-color:var(--blue);
    color: var(--blue);
}

.redbtn{
    background-color: red;
    border: none;
    height:30px;
    width:30px;
    border-radius: 50%;
}

.yellowbtn{
    background-color: yellow;
    border: none;
    height:30px;
    width:30px;
    border-radius: 50%;
}

.greenbtn{
    background-color: green;
    border: none;
    height:30px;
    width:30px;
    border-radius: 50%;
}

.about {
    height: 65vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* .about::-webkit-scrollbar {
    display: none;
  } */
  
  /* Hide scrollbar for IE, Edge and Firefox */


.btntext{
    display: none;
    position:fixed;
    margin-top: -21px;
    text-align: center;
}
.ride:hover .btntext{
    margin-left: -8px;
    display: block;
}
.ride2:hover .btntext{
    display: block;
}
.ride3:hover .btntext{
    display: block;
}

#DraftorPost{
    z-index: 2000;
}

/* The Modal (background) */
.modalt {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%;
    overflow: auto; 
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4); 
  }
  
  .modal-contentt {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    padding-top: 0px;
    border: 1px solid #888;
    width: 80%;
  }
  
  .closet {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    width: fit-content;
    padding-left: 0px;
  }

  .backset{
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    width: fit-content;
    padding-left: 0px;
  }
  
  .closet:hover,
  .closet:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  .containerThis{
    height: 260px;
    overflow: hidden;
   
}

.itemx{

    text-align: center;

}

.wrighirhgipjpihdfpjhpidfpi{
   
  height: 200px;
  object-fit: cover;
}

.kgnsiohgoidhzsdaojd{
    font-size: 10px;
}


.img-thumbnaili{
    height: 250px;
}

#Bbtn ,#Fbtn{
    bottom: 200px;
}
/* #DP{
    width: 270px;
    height: 180px;
} */
