.Appbodyy {
  font-family: sans-serif;
  text-align: center;
}

.confettie-wrap {
  background-color: white;
}
.confettie-wrap h3 {
  margin: 0;
  color: white;
  letter-spacing: 2px;
}
.shadowbox {
  box-shadow: rgba(48, 48, 48, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px !important;
}
