#floatingInput, #floatingPassword, #floatingContact, #floatingform, #floatingCPassword {
    width: 350px;
    border: 1px solid rgb(0, 0, 0);
}

#floatingInput:focus, #floatingPassword:focus, #floatingContact:focus, #floatingform:focus, #floatingCPassword:focus {
    border-color: rgba(0, 0, 0, 0.8);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(214, 12, 170, 0.6);
    outline: 0 none;
}

#floatingInput:hover, #floatingPassword:hover, #floatingContact:hover, #floatingform:hover, #floatingCPassword:hover {
    border-color: rgba(0, 0, 0, 0.8);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(232, 12, 240, 0.6);
    outline: 0 none;
}

.signin {
    box-shadow: 0 1px 1px rgba(255, 87, 87, 0.075) inset, 0 0 8px rgba(59, 58, 59, 0.6);
    outline: 0 none;
}

.forgotpassword {
    text-decoration: none;
    font-weight: 600;

}

#signinbtn {
    background-color: #55374a;
    color: white;
    width: 280px;
    border-radius: 100px;
}


#floatingContact::-webkit-inner-spin-button,
#floatingContact::-webkit-outer-spin-button {

    -webkit-appearance: none;
    margin: 0;
}


input[type="date"]:before {
    content: attr(placeholder) !important;
    color: #aaa;
    margin-right: 0.5em;
}

input[type="date"]:focus:before,
input[type="date"]:valid:before {
    color: rgb(0, 0, 0);
    content: "";
}

.termsandcondition a {
    text-decoration: none;
}


#contain {
    margin-left: 0px;
    display: flex;
    /* width: 500px; */
    overflow: hidden;
    /* box-shadow: 0 1px 1px rgba(255, 87, 87, 0.075) inset, 0 0 8px rgba(59, 58, 59, 0.6);
     outline: 0 none; */

}

.woiufhowhfrfibjirh{
    padding-top: -50px;
}

.sbdciuwg{
    margin-right: -200px;
}

.eiugcfiu{
    margin-right: -200px;
}


@media only screen and (max-width: 425px) {
    /*Small smartphones [325px -> 425px]*/


 

    #floatingInput, #floatingPassword, #floatingContact, #floatingform, #floatingCPassword {
        width: 100%;
        border: 1px solid rgb(0, 0, 0);
    }

    #signinbtn {
        background-color: #55374a;
        color: white;
        width: 280px;
        border-radius: 100px;
    }

    .signin {
        width: 100%;
        /* margin-top: 60px; */
    }


   

    #contain {
        /* margin-left: 500px; */
        display: flex;
        width: 100%;
        overflow: hidden;
        /* box-shadow: 0 1px 1px rgba(255, 87, 87, 0.075) inset, 0 0 8px rgba(59, 58, 59, 0.6);
     outline: 0 none; */

    }
}

.sjdhfkshdkjcghu{
   
width: min-content;
margin-left: 320px;
margin-top: -30px;
 
}



@media only screen and (max-width: 425px){
    .sjdhfkshdkjcghu{
   
        width: min-content;
        margin-left: 325px;
        margin-top: -30px;
         
        }
}
@media only screen and (max-width: 372px){
    .sjdhfkshdkjcghu{
   
        width: min-content;
        margin-left: 285px;
        margin-top: -30px;
         
        }
}
@media only screen and (max-width: 345px){
    .sjdhfkshdkjcghu{
   
        width: min-content;
        margin-left: 250px;
        margin-top: -30px;
         
        }
}
/* @media only screen and (max-width: 360px){
    .sjdhfkshdkjcghu{
   
        width: min-content;
        margin-left: 265px;
        margin-top: -30px;
         
        }
}
@media only screen and (max-width: 337px){
    .sjdhfkshdkjcghu{
   
        width: min-content;
        margin-left: 217px;
        margin-top: -30px;
         
        }
} */



