.serrow {
    margin-left: 290px;
}

.dfrty {
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.dfrty::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.dfrty {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.postrt {
    width: 70%;
    padding-left: 100px;
    padding-right: 100px;

}

.notificationrt {
    width: 30%;

}

@media screen and (max-width:1300px) {
    .serrow {
        margin-left: 155px;
    }

}

@media screen and (max-width:1100px) {
    .postrt {

        padding-left: 50px;
        padding-right: 50px;
    }

}

@media screen and (max-width:950px) {
    .postrt {
        width: 60%;
        padding-left: 0px;
        padding-right: 0px;

    }

    .notificationrt {
        width: 40%;
    }

}

@media screen and (max-width:767px) and (min-width:700px) {
    .postrt {
        width: 50%;
        padding-left: 0px;
        padding-right: 0px;

    }

    .notificationrt {
        width: 40%;

    }

}

@media screen and (max-width:700px) {
    .postrt {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;

    }

    .notificationrt {
        display: none;

    }

}

@media screen and (max-width: 576px) {
    .serrow {
        margin-left: 0px;
    }

    .btn4 {}
}