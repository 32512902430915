*
{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --white: #fff;
    --gray:#f5f5f5;
    --black1:#222;
    --black2:#999;
}

/* body {
    min-height: 100vh;
    overflow-x: hidden;
    
} */

.container1 {
    position: relative;
    width: 100%;
}



.main.active {
    width: calc(100% - 80px);

    left: 100px;
}

.topbar {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}

.toggle {
    position: relative;
    top: 0;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5em;
    cursor: pointer;
}




