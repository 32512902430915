* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}



/* body {
    min-height: 100vh;
    overflow-x: hidden;
    
} */

.container1 {
    position: relative;
    width: 100%;
}



.main.active {
    width: calc(100% - 80px);

    left: 100px;
}

.topbar {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}

.toggle {
    position: relative;
    top: 0;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5em;
    cursor: pointer;
}

.RollContainer {
    height: calc(100% - 126px) !important;
    overflow: scroll;
}

.skeleton {
    background-color: #733380;
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 80%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}