* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --blue: #55374a;
  --white: #fff;
  --gray: #f5f5f5;
  --black1: #222;
  --black2: #999;
}

/* body {
    min-height: 100vh;
    overflow-x: hidden;
    
} */

.container1 {
  position: relative;
  width: 100%;
}

.main {
  position: absolute;

  width: calc(100% - 300px);
  /* left: 300px; */
  background: var(--white);
  transition: 0.5%;
}

.main.active {
  width: calc(100% - 80px);

  left: 80px;
}

.cardBox {
  position: relative;
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}

.cardBox .card {
  position: relative;
  background: var(--white);
  padding: 30px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.4);
}

.cardBox .card .numbers {
  position: relative;
  font-weight: 500;
  font-size: 2.5em;
  color: var(--blue);
}

.cardBox .card .cardName {
  color: var(--black2);
  font-size: 1.1em;
  margin-top: 5px;
}

.cardBox .card .iconBox {
  font-size: 3.5em;
  color: var(--black2);
}

.cardBox .card:hover {
  background: var(--blue);
}

.cardBox .card:hover .numbers,
.cardBox .card:hover .cardName,
.cardBox .card:hover .iconBox {
  color: var(--white);
}

.whiteclr {
  color: white !important;
}
