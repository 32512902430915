/* .navbar .nav-item .dropdown-menu {
  display: block;
  visibility: hidden;
  top: 100%;
  transform: rotateX(-75deg);
  transform-origin: 0% 0%;
  transition: 0.5s;
  opacity: 0;
} */
.blinkkk {
  animation: blinker 0.7s linear infinite;
}

@keyframes blinkkk {
  50% {
    opacity: 0;
  }
}

#freeblink {
  animation: blinker 0.7s linear infinite;
}

@keyframes freeblink {
  70% {
    opacity: 0;
  }
}

.blinkkk {
  animation: blinker 0.7s linear infinite;
}

@keyframes blinkkk {
  50% {
    opacity: 0;
  }
}
.szz {
  width: 150px;
  margin-right: 40px;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .szz {
    width: 190px;
  }
}
@media screen and (min-width: 1200px) {
  .szz {
    width: 190px;
  }
}

#navnew .nav-link {
  padding-left: 0px !important;
}

.bgvipar {
  background-color: #012060;
}

.controlbar_border {
  border: none !important;
  box-shadow: unset !important;
}

.controlbar_border:focus {
  border: none !important;
  outline: none !important;
  box-shadow: unset !important;
}

.btnmainvipar {
  border: 1px solid rgb(5, 28, 73) !important;
  color: white !important;
  background: rgb(5, 28, 73) !important;
}

.btnsemi {
  border: 1px solid rgb(5, 28, 73) !important;
  color: rgb(5, 28, 73) !important;
  background: white !important;
}

.btnsemi :focus {
  box-shadow: unset !important;
}

.prochure {
  font-family: 'maindra' !important;
  color: #5d334e !important;
  font-size: 18px;
}

.verticals {
  font-family: 'maindra' !important;
  font-size: 18px;
}

.dropdownreactstyle button:active {
  border: none;
  box-shadow: none;
}
.dropdownreactstyle button:hover {
  color: black;
  box-shadow: none;
}

.dropdownreactstyle button:focus {
  box-shadow: none !important;
  border: none !important;

  outline: none !important;
}

.dropdownnnn:hover {
  background-color: white;
  color: black;
}

.fcgrey {
  color: #cfd6e1 !important;
}

.zxc {
  color: #012060;
  opacity: 1;
  border-top: 3px solid;
}

.active-link-vipar {
  color: rgb(155 212 20) !important;
}

.maindrafont {
  font-family: 'maindra' !important;
}
.viparfooter {
  font-size: 30px;
  color: #99d050;
  font-family: 'monotype Corsiva';
}

.footlightfont {
  font-family: 'footlight' !important;
  font-size: 18px !important;
  font-weight: 300 !important;
}

.boldfive {
  font-weight: 900 !important;
}

@font-face {
  font-family: footlight;
  src: url('../../FootlightMTProLight.otf');
}

@font-face {
  font-family: maindra;
  src: url('../../MAIAN.TTF');
}

@font-face {
  font-family: calibri;
  src: url('../../Calibri\ Regular.ttf');
}

.calibrifont {
  font-family: calibri !important;
}

.zxxxx {
  background-color: #e9eef5;
}

.zxxxx:hover {
  color: #e9eef5;
  background-color: black;
}

@media screen and (max-width: 768px) {
  #foooo {
    min-height: calc(100vh - 122px);
  }
}
@media screen and (min-width: 768px) {
  #foooo {
    min-height: calc(100vh - 122px);
  }
  #fooooo {
    min-height: calc(100vh - 122px);
  }
  #foter {
    min-height: calc(100vh - 122px);
  }

  .sksksk {
    width: 250px;
  }
  .dfdfdf {
    display: flex !important;
    justify-content: center;
  }
}

@media only screen and (max-width: 425px) {
  /* Small smartphones [325px -> 425px]
  .media {
    color: rosybrown;
  } */
  /* #foooo {
    min-height: calc(100vh - 85px - 242px);
  } */
  #fooooo {
    min-height: calc(100vh - 122px);
  }
  #foter {
    min-height: calc(100vh - 122px);
  }

  #fooo {
    min-height: calc(100vh - 63px - 218px);
  }

  #foo {
    min-height: calc(100vh - 63px - 122px);
  }
  #fo {
    min-height: calc(100vh - 240px - 122px);
  }
  #fot {
    min-height: calc(100vh - 190px - 122px);
  }
}
@media only screen and (min-width: 425px) {
  /* .media {
    color: green;
  } */

  #fooo {
    min-height: calc(100vh - 63px - 260px);
  }
  #foo {
    min-height: calc(100vh - 63px - 122px);
  }
  #fo {
    min-height: calc(100vh - 206px - 122px);
  }
  #fot {
    min-height: calc(100vh - 190px - 122px);
  }
}

.boldfont {
  font-weight: 900 !important;
}

/* age */
.age::after {
  content: 'years';
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.age {
  display: inline-block;
  position: relative;
}

.age::after {
  position: absolute;
  top: 5px;
  right: 1em;
  transition: all 0.05s ease-in-out;
}
age:hover::after,
age:focus-within::after {
  right: 1.5em;
}
@supports (-moz-appearance: none) {
  age::after {
    right: 1.5em;
  }
}

@media only screen and (max-width: 480px) {
  .age::after {
    position: absolute;
    top: 6px;
    left: 100px;
    transition: all 0.05s ease-in-out;
  }
}
@media only screen and (min-width: 481px) and (max-width: 767px) {
  .age::after {
    position: absolute;
    top: 6px;
    left: 100px;
    transition: all 0.05s ease-in-out;
  }
}

@media screen and (min-width: 768px) {
  .age::after {
    position: absolute;
    top: 6px;
    left: 100px;
    transition: all 0.05s ease-in-out;
  }
}
@media screen and (min-width: 992px) {
  .age::after {
    position: absolute;
    top: 6px;
    left: 100px;
    transition: all 0.05s ease-in-out;
  }
}

.pxfont25 {
  font-size: 25px !important;
}

.pxfont15 {
  font-size: 15px !important;
}

.mono {
  font-family: 'monotype Corsiva';
  color: #cfd6e1 !important;
  font-size: 22px !important;
}

/* age */
