/* body {
  background-color: #fbfbfb;
}
@media (min-width: 991.98px) {
  main {
    padding-left: 240px;
  }
}

/* Sidebar */
.sidebar {
  position: fixed;
  overflow-y: scroll;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 58px 0 0;
  /* Height of navbar */
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
  width: 240px;
  z-index: 600;
}

.mainSpace {
  padding-left: 245px;
  padding-right: 5px;
  padding-top: 65px;
  height: 100vh;
}

#main-navbar{
  height: 60px;
  overflow: hidden  ;
}
@media (max-width: 991.98px) {
  .sidebar {
    width: 100%;
  }

  .mainSpace {
    padding-right: 0px;
    padding-left: 0px;
  }
}

.sidebar .active {
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  /* Scrollable contents if viewport is shorter than content. */
}

.pageleft {
  margin-left: 100px;
}

/* /////////////////////////////////////////////////////////////////////////////// */